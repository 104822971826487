<template>
  <div>
    <div class="content">
      <!-- <Logodia></Logodia> -->
      <!-- <div class="zhanweifroorder"></div> -->
      <div class="mainbox" v-if="orderType == 'detailOrder'">
        <div>
          <div v-if="orderInfo.status == 0">
            <div class="statefont">待付款</div>
            <div class="statelable">
              剩余支付时间
              <div class="countdownbox">
                <van-count-down
                  :time="orderInfo.outPayTimenum"
                  @finish="getOrderInfo"
                >
                  <template #default="timeData">
                    <span class="block"
                      ><span v-if="timeData.hours < 10">0</span
                      >{{ timeData.hours }}</span
                    >
                    <span class="colon">:</span>
                    <span class="block"
                      ><span v-if="timeData.minutes < 10">0</span
                      >{{ timeData.minutes }}</span
                    >
                    <span class="colon">:</span>
                    <span class="block"
                      ><span v-if="timeData.seconds < 10">0</span
                      >{{ timeData.seconds }}</span
                    >
                  </template>
                </van-count-down>
              </div>
            </div>
          </div>
          <div v-if="orderInfo.status == 1">
            <div class="statefont">待发货</div>
            <div class="statelable">您的包裹整装待发</div>
          </div>
          <div v-if="orderInfo.status == 2">
            <div class="statefont">已发货</div>
            <div class="statelable">您的包裹已快马加鞭奔向你啦~</div>
          </div>
          <div v-if="orderInfo.status == 3">
            <div class="statefont">已完成</div>
            <div class="statelable">交易已完成，欢迎下次光临~</div>
          </div>
          <div v-if="orderInfo.status == 5">
            <div class="statefont">已取消</div>
            <div class="statelable">你已取消了这个订单~</div>
          </div>
          <div v-if="orderInfo.status == 6">
            <div class="statefont">部分发货</div>
            <div class="statelable">此订单将为你分包发送快递~</div>
          </div>
        </div>

        <div class="margintop40">
          <van-divider dashed></van-divider>
        </div>
        <div class="addressinfoboxz">
          <div class="addressinfobox">
            <div>
              <span class="username">{{ orderInfo.deliverName }}</span>
              <span class="usertel">{{ orderInfo.deliverTel }}</span>
            </div>
            <div class="margintop30">
              <span v-if="orderInfo.deliverProvince">{{
                orderInfo.deliverProvince
              }}</span>
              <span v-if="orderInfo.deliverCity">{{
                orderInfo.deliverCity
              }}</span>
              <span v-if="orderInfo.deliverArea">{{
                orderInfo.deliverArea
              }}</span>
              <span v-if="orderInfo.deliverAddress">{{
                orderInfo.deliverAddress
              }}</span>
            </div>
            <div class="addressiconbox">
              <img
                class="addressicon"
                src="../../assets/imgs/dingwe@3x.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mainbox" v-if="orderType == 'saleOrder'">
        <div v-if="orderInfo.status == 0">
          <div class="statefont">处理中</div>
          <div class="statelable">正在处理退款，请耐心等待</div>
        </div>
        <div v-if="orderInfo.status == 1">
          <div class="statefont">商家已同意退款</div>
          <div class="statelable">货款将在1~3个工作日返回你的账户</div>
        </div>
        <div v-if="orderInfo.status == 2">
          <div class="statefont">退款失败</div>
          <div class="statelable">
            商家已拒绝您的退款申请，如有疑问请联系客服
            {{ orderInfo.rejectReason }}
          </div>
        </div>
        <div v-if="orderInfo.status == 3">
          <div class="statefont">已取消</div>
          <div class="statelable">已取消退款申请</div>
        </div>
      </div>
      <div
        class="mainbox"
        v-if="
          (orderInfo.status == 2 ||
            orderInfo.status == 3 ||
            orderInfo.status == 6) &&
          orderType == 'detailOrder'
        "
        @click="getLogistics"
      >
        <div class="flexbox">
          <div class="courierlable">物流信息</div>
          <div class="couriercompanybox">
            <span>{{ orderInfo.courierCompany }}</span>
            <span class="marginleft20">{{ orderInfo.courierNumber }}</span>
          </div>
        </div>
      </div>
      <div class="mainbox">
        <div class="flexbox marginbottom60">
          <div class="titlelable">商品信息</div>
          <div class="titleval">共{{ orderInfo.num }}件</div>
        </div>

        <div
          class="marginb5"
          v-for="item in orderInfo.productList"
          :key="item.productId"
        >
          <div class="mainitembox">
            <div>
              <img class="mainimg" :src="item.productMainImage" alt="" />
            </div>
            <div class="mainiteninfobox">
              <div class="flexboxtwo">
                <div class="infonamebox">{{ item.productName }}</div>
                <div class="infotitlebox"></div>
                <div class="skuinfo" v-if="item.productSkuName">
                  <div
                    class="skuinfoitem"
                    v-for="(ctem, index) in item.productSkuName"
                    :key="index"
                  >
                    {{ ctem }}
                  </div>
                </div>
                <div class="flexbox">
                  <div class="skuinfoprice">￥{{ item.unitPrice }}</div>
                  <div class="skunum">x{{ item.num }}</div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="flexboxthree"
            v-if="
              orderType == 'detailOrder' &&
              item.status == 0 &&
              orderInfo.status != 5 &&
              orderInfo.status != 0
            "
          >
            <div class="refundbtn" @click="toSalesReturn(item)">申请退款</div>
          </div>
          <!-- <div
            class="flexboxthree"
            v-if="orderType == 'detailOrder'"
          >
            <div class="refundbtn" @click="toSalesReturn(item)">申请退款</div>
          </div> -->
          <div
            class="flexboxthree"
            v-if="orderType == 'detailOrder' && item.status == 1"
          >
            <div class="refundlable">申请售后中</div>
          </div>
          <div
            class="flexboxthree"
            v-if="orderType == 'detailOrder' && item.status == 2"
          >
            <div class="refundlable">已售后</div>
          </div>
        </div>
        <div class="margintop41">
          <van-divider :style="{ color: '#c4c4c4' }"></van-divider>
        </div>

        <div class="salenumbox" v-if="orderType == 'saleOrder'">
          共{{ orderInfo.num }}件,退款金额：
          <span class="saleamountbox">￥{{ orderInfo.amount }}</span>
        </div>
        <div class="mainbox" v-if="orderType == 'saleOrder'"></div>
      </div>
      <div class="mainbox pad50" v-if="orderType == 'detailOrder'">
        <div class="flexbox">
          <div>商品合计</div>
          <div>￥{{ orderInfo.totalAmount }}</div>
        </div>
        <div class="flexbox">
          <div>配送费</div>
          <div>￥{{ orderInfo.freightAmount }}</div>
        </div>
        <div class="flexbox" v-if="orderInfo.integralAmount > 0">
          <div>积分优惠</div>
          <div>￥{{ orderInfo.integralAmount }}</div>
        </div>
        <div class="flexbox" v-if="orderInfo.couponAmount > 0">
          <div>优惠券优惠</div>
          <div>￥{{ orderInfo.couponAmount }}</div>
        </div>
        <div>
          <van-divider :style="{ color: '#c4c4c4' }"></van-divider>
        </div>
        <div class="flexbox">
          <div>实付总额</div>
          <div>￥{{ orderInfo.paidAmount }}</div>
        </div>
      </div>
      <div class="mainbox pad50" v-if="orderType == 'detailOrder'">
        <div class="flexbox">
          <div>订单信息</div>
        </div>
        <div class="flexbox">
          <div>订单编号</div>
          <div @click="copyOrderId">
            {{ orderInfo.orderNum }}
            <div class="copybtn">复制</div>
          </div>
        </div>
        <div class="flexbox">
          <div>下单时间</div>
          <div>{{ orderInfo.createTime }}</div>
        </div>
      </div>
    </div>
    <div class="zhanweifoot"></div>
    <div
      class="footbox"
      v-if="orderInfo.status == 0 && orderType == 'detailOrder'"
    >
      <div class="flexbox">
        <van-button class="defaultbtn" @click="cancelOrder"
          >取消订单</van-button
        >
        <van-button class="chongzhoubtn" @click="openPayPop"
          >立即支付</van-button
        >
      </div>
    </div>
    <div
      class="footbox"
      v-if="orderInfo.status == 2 && orderType == 'detailOrder'"
    >
      <div class="flexbox">
        <van-button class="defaultbtn" @click="getLogistics"
          >查看物流</van-button
        >
        <van-button class="chongzhoubtn" @click="confirmOrder"
          >确认收货</van-button
        >
      </div>
    </div>
    <div
      class="footbox textright"
      v-if="
        (orderInfo.status == 3 || orderInfo.status == 5) &&
        orderType == 'detailOrder'
      "
    >
      <van-button class="defaultbtn" @click="delOrder">删除订单</van-button>
    </div>

    <van-popup
      v-model="showPayTypePop"
      round
      closeable
      close-icon-position="top-left"
      safe-area-inset-bottom
      position="bottom"
      :style="{ height: '55%' }"
    >
      <div class="paytypebox">
        <div class="zhanweiforpaytypepop"></div>
        <div class="paypeicebox">￥{{ orderInfo.paidAmount }}</div>
        <div class="paylable">支付方式</div>
        <div class="paymainbox">
          <div class="flexbox">
            <div>
              <div>
                <div class="inlineblock">
                  <img
                    class="payicon"
                    src="../../assets/imgs/zfb@3x.png"
                    alt=""
                  />
                </div>
                <div class="inlineblock marginleft32">支付宝支付</div>
              </div>
            </div>
            <div class="checkjifenicon" @click="changgePayType('alipay')">
              <div v-if="payType == 'alipay'" class="checkjifeniconmain"></div>
            </div>
          </div>
        </div>
        <!-- <div class="paymainbox">
          <div class="flexbox">
            <div>
              <div>
                <div class="inlineblock">
                  <img
                    class="payicon"
                    src="../../assets/imgs/wx@3x.png"
                    alt=""
                  />
                </div>
                <div class="inlineblock marginleft32">微信支付</div>
              </div>
            </div>
            <div class="checkjifenicon" @click="changgePayType('wxpay')">
              <div v-if="payType == 'wxpay'" class="checkjifeniconmain"></div>
            </div>
          </div>
        </div> -->
        <div class="tipsbox">*温馨提示：请在指定时间内完成支付</div>
        <div class="paybtnbox" @click="payforOrder">确认付款</div>
      </div>
    </van-popup>
    <van-popup
      v-model="showHaisiyPop"
      round
      closeable
      close-icon-position="top-left"
      safe-area-inset-bottom
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div>
        <div class="zhanweiforhaisiy"></div>

        <van-tabs v-model="activeTab" animated>
          <van-tab
            v-for="(item, index) in haisiyList"
            :title="item.name"
            :key="index"
          >
            <div>
              <div class="mainbox">
                <div
                  class="marginb5"
                  v-for="(etem, j) in item.productList"
                  :key="j"
                >
                  <div class="mainitembox">
                    <div>
                      <img
                        class="mainimg"
                        :src="etem.productMainImage"
                        alt=""
                      />
                    </div>
                    <div class="mainiteninfobox">
                      <div class="flexboxtwo">
                        <div class="infonamebox">{{ etem.productName }}</div>
                        <div class="infotitlebox"></div>
                        <div class="skuinfo" v-if="etem.productSkuName">
                          <div
                            class="skuinfoitem"
                            v-for="(ctem, index) in etem.productSkuName"
                            :key="index"
                          >
                            {{ ctem }}
                          </div>
                        </div>
                        <div class="flexbox">
                          <div class="skuinfoprice">￥{{ etem.unitPrice }}</div>
                          <div class="skunum">x{{ etem.num }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flexbox bottomline">
                <div class="expNamebox">
                  {{ item.logisticsHtml.expName }}
                  {{ item.logisticsHtml.number }}
                </div>
                <div class="p-r-30">
                  <span
                    class="copybtntwo"
                    @click="copyexpNumber(item.logisticsHtml.number)"
                  >
                    复制
                  </span>
                  <span> | </span>
                  <span
                    class="callbtn"
                    v-if="item.logisticsHtml.courierPhone"
                    @click="callPhone(item.logisticsHtml.courierPhone)"
                  >
                    打电话
                  </span>
                </div>
              </div>

              <van-steps direction="vertical" :active="0">
                <van-step v-for="(ctem, i) in item.logisticsHtml.list" :key="i">
                  <div class="haisiyInfolable">{{ ctem.status }}</div>
                  <div>{{ ctem.time }}</div>
                </van-step>
              </van-steps>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </van-popup>
    <van-popup
      v-model="showTuihuoPop"
      round
      closeable
      close-icon-position="top-left"
      safe-area-inset-bottom
      position="bottom"
      :style="{ height: '90%' }"
    >
      <div>
        <div class="zhanweiforhaisiy"></div>
        <div class="tuihuotitle">选择商品</div>
        <div class="tuihuomainbox">
          <div
            class="tuihuomainitembox"
            v-for="(item, index) in saleProductList"
            :key="index"
          >
            <div>
              <img class="tuihuomainimg" :src="item.productMainImage" alt="" />
            </div>
            <div class="tuihuomainiteminfobox">
              <div class="flexboxtwo">
                <div class="infonamebox">{{ item.productName }}</div>
                <div class="infotitlebox"></div>
                <div class="skuinfo" v-if="item.productSkuName">
                  <div
                    class="skuinfoitem"
                    v-for="(ctem, index) in item.productSkuName"
                    :key="index"
                  >
                    {{ ctem }}
                  </div>
                </div>
                <div class="tuihuoprice">
                  数量{{ item.num }}
                  <span class="tuihuonum">￥{{ item.rtAmount }}</span>
                </div>
              </div>
            </div>
            <div class="tuihuoiconbox" @click.stop="changeTuihuo(item)">
              <img
                class="tuihuoicon"
                v-if="item.isCheked"
                src="../../assets/imgs/xuanzhong@3x.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="tuihuofootbox">
          <div class="flexbox">
            <div class="tuihuofooticonbox" @click.stop="changTuihuoAll">
              <img
                class="tuihuoiconbox"
                v-if="allCheckedTuihuo"
                src="../../assets/imgs/xuanzhong@3x.png"
                alt=""
              />
            </div>
            <div class="tuihuofootpricebox">
              <span>退款金额：</span>
              <span class="tuihuofootprice"> ￥{{ allTuihuoPrice }} </span>
            </div>
            <div class="tuihuofootbtn" @click="toSalesReturn">申请退款</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  orderInfo_api,
  saleOrderInfo_api,
  cancelOrder_api,
  haisiyInfo_api,
  saleProduct_api,
  confirmOrder_api,
  delOrder_api,
  payOrderforWXW_api,
} from "../../apis/order";
import Logodia from "../../components/Logodia.vue";
export default {
  components: {
    Logodia,
  },
  data() {
    return {
      orderInfo: {
        afterSaleNo: "",
        candelRemarks: "",
        candelTime: "",
        confirmTime: "",
        couponAmount: 0,
        couponIds: "",
        courierCompany: "",
        courierNumber: "",
        courierRemarks: "",
        deliverAddress: "",
        deliverArea: "",
        deliverCity: "",
        deliverId: "",
        deliverName: "",
        deliverProvince: "",
        deliverTel: "",
        deliverTime: "",
        freightAmount: "",
        integral: "",
        integralAmount: "",
        isAfterSale: 0,
        isCoupon: 0,
        isIntegral: 0,
        isPay: 0,
        num: 0,
        orderNum: "",
        outPayTime: "",
        paidAmount: "",
        payTime: "",
        payType: 1,
        productList: [],
        rentAmount: "",
        status: 0,
        totalAmount: "",
        transactionId: "",
        userId: "",
      },
      orderId: "",
      orderType: "",
      payType: "alipay",
      showPayTypePop: false,
      haisiyInfo: "",
      showHaisiyPop: false,
      saleProductList: [],
      showTuihuoPop: false,
      allTuihuoPrice: 0,
      allCheckedTuihuo: false,
      haisiyList: [],
      activeTab: 0,
    };
  },
  created() {
    console.log(this.$route.query);
    this.orderId = this.$route.query.id;
    // this.$bus.$emit('imOrderId',this.orderId)

    this.orderType = this.$route.query.type;
    if (this.orderType == "detailOrder") {
      this.getOrderInfo();
    } else if (this.orderType == "saleOrder") {
      this.getSaleOrderInfo();
    }
  },
  mounted() {
    document.addEventListener("visibilitychange", function (e) {
      console.log("visibilitychange", e);
    });
  },
  methods: {
    async getOrderInfo() {
      let { code, data } = await orderInfo_api({
        id: this.orderId,
      });
      if (code == 0) {
        this.orderInfo = data;
        console.log(this.orderInfo);
        if (this.orderInfo.status == 0) {
          this.orderInfo.outPayTimenum =
            Number(this.orderInfo.outPayTime) - new Date().getTime();
          console.log(this.orderInfo.outPayTimenum);
        }

        m7ProductInfo.title = data.productList[0].productName;
        m7ProductInfo.img = data.productList[0].productMainImage;
        m7ProductInfo.price = "￥" + data.totalAmount;
        m7ProductInfo.attr_one = {
          color: "#000000",
          content: `X${data.num}`,
        };
        let attrTwo = null;
        if (data.status == 0) {
          attrTwo = "待付款 ";
        } else if (data.status == 1) {
          attrTwo = "待发货 ";
        } else if (data.status == 2) {
          attrTwo = "待收货 ";
        } else if (data.status == 3) {
          attrTwo = "确认收货 ";
        } else if (data.status == 4) {
          attrTwo = "售后 ";
        } else if (data.status == 5) {
          attrTwo = "已取消 ";
        } else if (data.status == 6) {
          attrTwo = "部分发货 ";
        }
        m7ProductInfo.attr_two = { color: "#000000", content: attrTwo };
        m7ProductInfo.other_title_one = `订单号:${data.orderNum}`;
        m7ProductInfo.other_title_two = `下单时间:${data.createTime}`;
        console.log(m7ProductInfo);
      }
    },
    async getSaleOrderInfo() {
      let { code, data } = await saleOrderInfo_api({
        id: this.orderId,
      });
      if (code == 0) {
        this.orderInfo = data;
      }
    },

    copyOrderId() {
      var input = document.createElement("input"); // 创建input对象
      input.value = this.orderInfo.orderNum; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$toast.success("成功复制订单号");
    },
    copyexpNumber(num) {
      var input = document.createElement("input"); // 创建input对象
      input.value = num; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$toast.success("成功复制运单号");
    },
    callPhone(phone) {
      window.location.href = "tel:" + phone;
    },

    cancelOrder() {
      this.$dialog
        .confirm({
          message: "确定要取消这个订单吗？",
        })
        .then(() => {
          this.cancelOrderFn();
        });
    },
    async cancelOrderFn() {
      let { code, data } = await cancelOrder_api({
        id: this.orderId,
      });
      if (code == 0) {
        this.$toast.success("订单已取消");
        if (this.orderType == "detailOrder") {
          this.getOrderInfo();
        } else if (this.orderType == "saleOrder") {
          this.getSaleOrderInfo();
        }
      }
    },
    changgePayType(type) {
      this.payType = type;
    },
    async testPay() {
      let { code, data } = await testPay_pai({
        id: this.orderId,
      });
      if (code == 0) {
        this.$toast("购买成功");
      }
    },
    openPayPop() {
      this.showPayTypePop = true;
    },
    async getLogistics() {
      let { code, data, message } = await haisiyInfo_api({
        id: this.orderId,
       
      });
      if (code == 0) {
        this.haisiyList = data;
        this.activeTab = 0;
        this.haisiyList.forEach((item) => {
          let logisticsHtml = JSON.parse(item.logistics).result;
          this.$set(item, "logisticsHtml", logisticsHtml);
        });

        //  this.haisiyInfo = JSON.parse(message).result;
        this.showHaisiyPop = true;
      }
    },
    confirmOrder() {
      this.$dialog
        .confirm({
          message: "请确认收到你的商品吗？",
        })
        .then(() => {
          this.confirmOrderFn();
        });
    },
    async confirmOrderFn(id) {
      let { code, data } = await confirmOrder_api({
        id: this.orderId,
      });
      if (code == 0) {
        this.$toast.success("欢迎下次光临~");
        if (this.orderType == "detailOrder") {
          this.getOrderInfo();
        } else if (this.orderType == "saleOrder") {
          this.getSaleOrderInfo();
        }
      }
    },
    async getSaleProduct() {
      let { code, data } = await saleProduct_api({
        id: this.orderId,
      });
      if (code == 0) {
        this.saleProductList = data;
        this.saleProductList.forEach((item) => {
          this.$set(item, "isCheked", false);
        });
        this.showTuihuoPop = true;
      }
    },
    changeTuihuo(item) {
      item.isCheked = !item.isCheked;
      this.allTuihuoPrice = 0;
      let checkedNum = 0;
      this.saleProductList.forEach((item) => {
        if (item.isCheked) {
          this.allTuihuoPrice = this.allTuihuoPrice + item.rtAmount;
          checkedNum++;
        }
      });
      if (checkedNum == this.saleProductList.length) {
        this.allCheckedTuihuo = true;
      } else {
        this.allCheckedTuihuo = false;
      }
      this.allTuihuoPrice = this.allTuihuoPrice.toFixed(2);
    },
    changTuihuoAll() {
      this.allCheckedTuihuo = !this.allCheckedTuihuo;
      this.allTuihuoPrice = 0;
      this.saleProductList.forEach((item) => {
        item.isCheked = this.allCheckedTuihuo;
        this.allTuihuoPrice = this.allTuihuoPrice + item.rtAmount;
      });

      if (!this.allCheckedTuihuo) {
        this.allTuihuoPrice = "0.00";
      } else {
        this.allTuihuoPrice = this.allTuihuoPrice.toFixed(2);
      }
    },
    toSalesReturn(item) {
      // let saledArr = [];
      // this.saleProductList.forEach((item) => {
      //   if (item.isCheked) {
      //     saledArr.push(item.id);
      //   }
      // });
      // if (saledArr == []) {
      //   this.$toast("请选择需要退货的商品~");
      // } else {
      //   let saledStr = saledArr.join(",");
      //   this.$router.push(
      //     {
      //       path: "/applysaledorder",
      //       query: {
      //         id: this.orderId,
      //       },
      //     },
      //     () => {}
      //   );
      // }

      this.$router.push(
        {
          path: "/applysaledorder",
          query: {
            id: item.id,
          },
        },
        () => {}
      );
    },
    delOrder() {
      this.$dialog
        .confirm({
          message: "确定要删除吗？",
        })
        .then(() => {
          this.delOrderFn();
        });
    },
    async delOrderFn() {
      let { code, data } = await delOrder_api({
        id: this.orderId,
      });
      if (code == 0) {
        this.$toast.success("记录已删除,页面即将跳转");
        setTimeout(() => {
          this.$router.push(
            {
              path: "/orderlist",
            },
            () => {}
          );
        }, 2000);
      }
    },
    //判断是否微信
    is_weixn() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    payforOrder() {
      if (this.is_weixn()) {
        if (this.payType == "alipay") {
          this.$toast("请右上角浏览器打开~");
        } else if (this.payType == "wxpay") {
          this.$toast("功能尚在开发中~");
        }
      } else {
        if (this.payType == "alipay") {
          this.$router.push({
            path: "/aliPay",
            query: { orderId: this.orderId },
          });
        } else if (this.payType == "wxpay") {
          this.weixinPayweb();
        }
      }
    },
    async weixinPayweb() {
      let { code, data, message } = await payOrderforWXW_api({
        id: this.orderInfo.id,
      });
      if (code == 0) {
        console.log("data", data);
        console.log("message", message);
        window.location.replace(message);
      }
    },

    // async payOrderforAli() {
    //   let { code, data, message } = await payOrderforAli_api({
    //     id: this.orderId,
    //   });
    //   if (code == 0) {
    //     console.log(message);
    //     const div = document.createElement("div");
    //     div.innerHTML = message; //res.data是返回的表单
    //     document.body.appendChild(div);
    //     document.forms.alipaysubmit.submit();
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 690px;
  margin: 0 auto;
}

.zhanweifroorder {
  width: 100%;
  height: 70px;
}

.mainbox {
  width: 630px;
  padding: 40px 30px;
  margin-bottom: 20px;
  background: #fff;
}

.expNamebox {
  margin-left: 30px;
  font-size: 28px;
}

.copybtntwo {
  font-size: 28px;
  font-family: PingFang SC;
  margin-right: 10px;
}
.callbtn {
  font-size: 28px;
  font-family: PingFang SC;
  margin-left: 10px;
}
.p-r-30 {
  padding-right: 30px;
}
.bottomline {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 20px;
}

.statefont {
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-bottom: 20px;
}

.statelable {
  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
}

.margintop40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.addressinfoboxz {
  position: relative;
}

.addressinfobox {
  margin-left: 56px;
  font-size: 26px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
}

.username {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}

.usertel {
  margin-left: 40px;
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}

.margintop30 {
  margin-top: 20px;
}

.addressiconbox {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0px;
  top: 30px;
}

.addressicon {
  width: 32px;
  height: 32px;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titlelable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.titleval {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #999999;
  letter-spacing: 5px;
}

.mainitembox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  // margin-bottom: 20px;
}

.mainimg {
  width: 180px;
  height: 180px;
  border-radius: 10px;
  object-fit: cover;
}

.mainiteninfobox {
  width: 420px;
  height: 180px;
}

.infonamebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
}

.infotitlebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}

.skuinfo {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}

.skuinfoitem {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.skuinfoprice {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.flexboxtwo {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.skunum {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  letter-spacing: 3px;
}

.marginbottom60 {
  margin-bottom: 60px;
}

.margintop41 {
  margin-top: 40px;
  margin-bottom: 30px;
}

.flexboxthree {
  display: flex;
  justify-content: end;
}

.refundbtn {
  width: 110px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #000000;
  line-height: 46px;
  text-align: center;

  font-size: 22px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
  margin-bottom: 20px;
}

.refundlable {
  width: 210px;
  height: 46px;
  background: #666666;

  line-height: 46px;
  text-align: center;

  font-size: 22px;
  font-family: PingFang;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
}

.pad50 {
  // padding-top: 50px;
  // padding-bottom: 50px;

  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  line-height: 60px;
}

.heifont {
  font-family: Heiti SC;
}

.countdownbox {
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  font-size: 28px;
  font-family: PingFang;
  font-weight: bold;
  color: #c40c0c;
}

.colon {
  display: inline-block;
  margin: 0 4px;
  color: #c40c0c;
  font-size: 28px;
  // line-height: 20px;
  //  color: #ee0a24;
  position: relative;
  top: -3px;
}

.block {
  display: inline-block;
  width: 36px;
  // color: #fff;
  font-size: 28px;
  color: #c40c0c;
  text-align: center;
  // background-color: #ee0a24;
}

.courierlable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.couriercompanybox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #986665;
}

.marginleft20 {
  margin-left: 20px;
}

.margintop20 {
  margin-top: 20px;
}

.zhanweifoot {
  width: 100%;
  height: 190px;
}

.copybtn {
  display: inline-block;
  // vertical-align: top;
  width: 50px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #cccccc;
  font-size: 20px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
  text-align: center;
  line-height: 28px;
}

.footbox {
  width: 690px;
  // height: 80px;
  background: #ffffff;
  position: fixed;
  left: 0px;
  bottom: 0px;
  padding: 30px 30px 40px 30px;
}

.defaultbtn {
  width: 330px;
  height: 80px;
}

.chongzhoubtn {
  width: 330px;
  height: 80px;

  background: #986665;
  color: #ffffff;
}

.paytypebox {
  .zhanweiforpaytypepop {
    width: 100%;
    height: 88px;
  }

  .paypeicebox {
    text-align: center;
    font-size: 36px;
    font-family: Heiti;
    font-weight: 500;
    color: #333333;
  }

  .paylable {
    margin-left: 30px;
    font-size: 24px;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
    margin-top: 32px;
    margin-bottom: 52px;
  }

  .paymainbox {
    margin-left: 30px;
    width: 663px;
    padding-right: 7px;
    padding-bottom: 45px;
    border-bottom: 1px solid #eeeeee;
    margin-top: 43px;

    font-size: 30px;
    font-family: PingFang;
    font-weight: bold;
    color: #333333;
  }

  .payicon {
    width: 38px;
    height: 38px;
  }

  .marginleft32 {
    margin-left: 32px;
  }

  .tipsbox {
    padding: 30px;
    font-size: 28px;
    // color: #eeeee2;
    color: #986665;
  }

  .paybtnbox {
    margin: 0 auto;
    margin-top: 60px;
    width: 690px;
    height: 80px;
    background: #986665;
    text-align: center;
    line-height: 80px;
    font-size: 26px;
    font-family: PingFang;
    font-weight: bold;
    color: #ffffff;
  }

  .checkjifenicon {
    width: 28px;
    height: 28px;
    background: #ffffff;
    border: 2px solid #986665;
    border-radius: 50%;
  }

  .checkjifeniconmain {
    width: 20px;
    height: 20px;
    background: #986665;
    border-radius: 50%;
    margin: 4px;
  }
}

.inlineblock {
  display: inline-block;
  vertical-align: top;
}

.haisiyInfolable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  //color: #666666;
}

.zhanweiforhaisiy {
  width: 100%;
  height: 80px;
}

.textright {
  text-align: right;
}

.tuihuotitle {
  font-size: 36px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  text-align: center;
}

.tuihuomainbox {
  margin: 0 auto;
  width: 690px;
}

.tuihuomainitembox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.tuihuomainimg {
  width: 180px;
  height: 180px;
  border-radius: 10px;
  object-fit: cover;
}

.tuihuomainiteminfobox {
  height: 180px;
  width: 410px;
}

.tuihuoiconbox {
  width: 32px;
  height: 32px;
  background: #dddddd;
  border-radius: 2px;
  display: inline-block;
  vertical-align: top;
}

.tuihuoicon {
  width: 32px;
  height: 32px;
}

.tuihuoprice {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}

.tuihuonum {
  font-size: 30px;
  font-family: Heiti SC;
  font-weight: 500;
  color: #000000;
}

.tuihuofootbox {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 690px;
  padding: 30px 30px 50px 30px;
  background: #fff;
}

.tuihuofooticonbox {
  height: 32px;
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;

  width: 32px;
  height: 32px;
  background: #dddddd;
  border-radius: 2px;
}

.tuihuofootpricebox {
  width: 370px;
  text-align: right;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
  line-height: 70px;
}

.tuihuofootprice {
  font-size: 36px;
  font-family: Heiti SC;
  font-weight: 500;
  color: #000000;
}

.tuihuofootbtn {
  width: 160px;
  height: 70px;
  background: #986665;
  text-align: center;
  line-height: 70px;

  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
}

.salenumbox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  text-align: right;
}

.saleamountbox {
  font-size: 30px;
  font-family: Heiti SC;
  font-weight: 500;
  color: #000000;
  margin-left: 10px;
}

.marginb5 {
  margin-bottom: 10px;
}
</style>